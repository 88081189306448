body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image{
  position: relative;
  height: 100vh;
}

.content{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
}

.content span.img-txt{
  background-color: #42b4e577;
  text-transform: uppercase;
  color: #ffffffb6;
  padding: 1rem;
  font-size: 3rem;
  font-weight: bolder;
  letter-spacing: 10px;
  font-family: 'BenchNine', sans-serif;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.788);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px)
}

h3{
  letter-spacing: 6px;
  text-transform: uppercase;
  font: 1.5rem;
  text-align: center;
}

.text-box{
  text-align: center;
  padding: 8% 1rem;
  margin: 2% 2% 2% 2%;
  text-align: justify;
  width: 30%;
}

.txt-container, .mi-vi-ob{
  display: flex;
  padding: 5px;
  justify-content: space-between;
}

.mi-vi-ob {
  width: 70%;
  margin: 2% 2% 2% 2%;
  padding: 1%;
}
.col-lg-4{
  padding: 1.5%;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (max-width: 768px) {
  .container{
    margin: 2%;
    display: grid;
  }

  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }

  .nidial-logo{
    width: 100px;
    height: 40px;
  }

  .content span.img-txt{
    font-size: 2rem;
    font-weight: bolder;
    letter-spacing: 5px;
  }

  .text-box, .mi-vi-ob {
    width: 100%;
    padding: 2%;
  }

  .content span.img-txt {
    font-size: 1.5rem;
    letter-spacing: 3px;
  }

  .contact-caption {
    display: block;
    margin-top: 60px;
    padding: 2%;
  }

  .contact-heading h2 {
    font-size: 50px;
  }

  .contact-info h3, .contact-form h3 {
    font-size: 20px;
  }

  .contact-info ul li {
    font-size: 12px;
  }

  .contact-info i.fa {
    font-size: 14px;
  }

  .contact-form input, .contact-form textarea {
    font-size: 11px;
  }

  .contact-form input.submit-btn {
    width: 140px;
    height: 40px;
    font-size: 18px;
  }

  .contact-form {
    padding-right: 20px;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.bd-mode-toggle .dropdown-menu .active .bi {
  display: block !important;
}

.nidial-logo{
  filter: brightness(1.1);
  mix-blend-mode: multiply;
}

#prov{
  filter: brightness(1.1);
  mix-blend-mode: multiply;
  box-shadow: 8px 5px 10px rgb(0, 0, 107);
  border-radius: 5%;
  margin: 2%;
}

#nav-link{
  color:#1a57a1;
  font-weight: bolder;
  font-size: 1.7rem;
}

#nav-link:hover{
  color:#52bc85;
}

h1, p, h2, h3, #nav-link {
  font-family: 'BenchNine', sans-serif;
}

.paragraph{
  font-size: 1.350rem;
}

.rounded-circle{
  box-shadow: 8px 5px 10px #1a57a1;
  margin-bottom: 10%;
}

.fw-normal{
  color: #1a57a1;
}

/* Flashing */
.hover13 .card:hover .card-img-top {
	opacity: 1;
	-webkit-animation: flash 2.5s;
	animation: flash 2.5s;
}
@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

/*contact section*/
.container{
  margin: 2%;
}

.contact-caption {
  display: flex;
  flex-wrap: wrap;
  margin-top: 120px;
  padding: 5%;
  justify-content: space-between;
  background-color: #212529;
  background-image: url("../src/img/map-image.png");
  background-repeat: no-repeat;
  background-position: center;
}
.contact-heading h2 {
  color: #fff;
  font-size: 90px;
}
.contact-info {
  padding-left: 40px;
}
.contact-info h3 {
  padding-left: 0;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid #26bdc1;
  padding-bottom: 12px;
  margin-bottom: 0;
}
.contact-form h3 {
  padding-left: 0;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid #26bdc1;
  padding-bottom: 12px;
  margin-bottom: 0;
}
.contact-info ul {
  margin: 0;
  padding: 0;
}
.contact-info ul li {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #fff;
}
.contact-info i.fa {
  font-size: 16px;
  padding-right: 12px;
  width: 25px;
  height: 38px;
}
.contact-info ul li span {
  font-weight: bold;
}
.contact-form input {
  width: 100%;
  height: 40px;
  background: #fff;
  font-size: 13px;
  color: #084a5c;
  font-family: 'Open Sans', sans-serif;
  padding: 12px;
  border: 0;
  margin-bottom: 12px;
}
.contact-form textarea {
  font-family: 'Open Sans', sans-serif;
  padding: 12px;
  width: 100%;
  height: 140px;
  border: 0;
  margin-bottom: 12px;
}
.info-detail {
  border-top: 1px solid #26bdc1;
  padding-top: 15px;
}
.form {
  border-top: 1px solid #26bdc1;
  padding-top: 15px;
  text-align: right;
}
.contact-form input.submit-btn {
  width: 180px;
  height: 50px;
  float: right;
  font-size: 24px;
  color: #fff;
  background: #26bdc1;
  background-repeat: no-repeat;
  padding: 0;
  font-family: 'BenchNine', sans-serif;
  font-weight: bold;
  border-radius: 10PX;
}
.contact-form {
  padding-right: 40px;
}

/******************************
Whatsapp button
********************************/
.btn-wsp{
  position:fixed;
  width:60px;
  height:60px;
  line-height: 63px;
  bottom:25px;
  right:25px;
  background:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:35px;
  box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
  z-index:100;
  transition: all 300ms ease;
}
.btn-wsp:hover{
  background: #20ba5a;
}

@media only screen and (min-width:320px) and (max-width:1199px){
  .btn-wsp{
      width:63px;
      height:63px;
      line-height: 66px;
}
}